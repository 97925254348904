import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";

@Component({
	selector: "tdata-date",
	templateUrl: "./date.html",
	styleUrls: ["./date.scss"],
})
export class TDataDate implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;

	constructor() {}

	ngOnInit() {
	}

	formateDate(inputDate: any) {
		const date = new Date(inputDate);
		const formattedDate = date.toLocaleString("en-US", {
			year: "numeric",
			month: "short",
			day: "numeric",
		});

		const formattedTime = date.toLocaleString("en-US", {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		});
		return { formattedDate, formattedTime };
	}

	ngOnDestroy() {}
}
