// Angular
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl } from "@angular/forms";

@Component({
	selector: "kt-update-status-dialog",
	templateUrl: "./update-status-dialog.component.html",
	styleUrls: ["./update-status-dialoge.component.scss"],
})
export class UpdateStatusDialogComponent implements OnInit {
	// selectedStatusForUpdate = new FormControl("");
	selectedStatusForUpdate = "0";
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	openDownDropdown: boolean = false;
	UserTypeValue = "UserType"
	constructor(public dialogRef: MatDialogRef<UpdateStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit() {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		}, 2500);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	handleDropdown() {
		this.openDownDropdown = !this.openDownDropdown;
	}
	handleClick(option) {
		this.selectedStatusForUpdate = option.value;
		this.UserTypeValue = option.text;
		this.handleDropdown();
	}
	updateStatus() {
		
		if (this.selectedStatusForUpdate === '0') {
			return;
		}

		/* Server loading imitation. Remove this */
		// this.viewLoading = true;
		// this.loadingAfterSubmit = true;
		// setTimeout(() => {
		this.dialogRef.close(this.selectedStatusForUpdate); // Keep only this row
		// }, 2500);
	}
}
