import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";

@Component({
	selector: "theader-toggle",
	templateUrl: "./toggle.html",
	styleUrls: ["./toggle.scss"],
})
export class TheaderToggle implements OnInit, OnDestroy {
	@Input() config: any;
	@Input() handleSort: any;
	@Input() sortOrder: any;
	toggleActive: boolean = false;

	constructor() {
		// this.config = data;


	}

	handleToggle() {
		this.toggleActive = !this.toggleActive;
		this.config.headerConf.action(this.toggleActive)
	}

	ngOnInit() {

	}

	ngOnDestroy() {
	}
}
