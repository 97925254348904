import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
	selector: "tdata-actions",
	templateUrl: "./actions.html",
	styleUrls: ["./actions.scss"],
})
export class TdataActions implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;
	@Input() showOnSelect = false;
	@Input() showOnHover = false;
	constructor(private dialog: MatDialog) {}

	async ngOnInit() {
	}

	ngOnDestroy() {}
}
