export class CsTableResource {
	public totalRecords: number = 10;
	public moduleName: string;
	public displayRecords: number = 10;
	public skipRecords: number = 0;
	public paginationRange = [];

	// date filter
	public gteDate = ''
	public lteDate = ''


	// sort
	public sortField = "_id";
	public sortOrder = "desc";

	public filter = {
		filter: {},
		search: "",
	};
	constructor(){
	}

	setDefaultValues(filter, totalRecords) {
		this.filter = filter || {};
		if (totalRecords) {
			this.paginationRange = this.createPaginationArray(totalRecords);
			this.totalRecords = totalRecords;
		}

		if (this.filter["sortField"]) {
			this.sortField = this.filter["sortField"];
			this.sortOrder = this.filter["sortOrder"];
		}
	}

	getInitialValue(moduleName) {
		let storage = localStorage.getItem("filter_" + moduleName);
		this.moduleName = moduleName;
		if (storage) {
			let data = JSON.parse(storage);
			this.filter = data.filter;
			this.skipRecords = data.skip;
			this.displayRecords = data.limit;
			this.sortField = data.sortField;
			this.sortOrder = data.sortOrder;
			return data.filter;
		}
	}

	setFilterValue() {
		console.log(this.moduleName)
		if (this.moduleName) {
			localStorage.setItem(
				"filter_" + this.moduleName,
				JSON.stringify({
					filter: this.filter,
					skip: this.skipRecords,
					limit: this.displayRecords,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				})
			);
		}
	}

	resetValue() {
		this.displayRecords = 10;
		this.skipRecords = 0;
		this.sortField = "_id";
		this.sortOrder = "desc";
		this.setFilterValue();
	}

	createPaginationArray(totalCount) {
		const paginationOptions = [10, 25, 50, 100, 200, 500, 1000];

		const filteredOptions = paginationOptions.filter((option) => option <= totalCount);

		const nextOption = paginationOptions.find((option) => option > totalCount);
		if (nextOption) {
			filteredOptions.push(nextOption);
		}

		return filteredOptions;
	}

	updatePagination(count) {
		this.paginationRange = this.createPaginationArray(count);
		this.totalRecords = count;
		this.setFilterValue();

	}

	updateFilter(filter) {
		this.filter = filter;
		this.setFilterValue();
	}
}
