import { AfterViewInit, Component, ViewChild, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Input, OnDestroy, EventEmitter, HostListener } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { ListingService } from "../../../common/services/listing.service";
// ../model/artisteList-model
import { ArtisteService } from "../../../modules/artiste/service/artiste.service";
import { NotificationService } from "../../../modules/managerinvite/service/service";
import { ArtisteListModel } from "../../../modules/artiste/model/artisteList-model";
import { ArtisteAddEditComponent } from "../../../modules/artiste/artiste-edit/artiste-edit.component";
import { GridOption } from "../../../../../app/core/base/layout/models/gridOption.model";
import { merge, Observable, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap, filter, throttleTime } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { CategoryService } from "../../../modules/category/service/category.service";
import { DeleteEntityDialogComponent } from "../../../../../app/views/partials/content/crud/delete-entity-dialog/delete-entity-dialog.component";
import { UpdateStatusDialogComponent } from "../../../../../app/views/partials/content/crud/update-status-dialog/update-status-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";

import { UserService } from "../../../modules/user/service/user.service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../../../../../app/core/base/layout/services/translation.service";
import { LunguageselectionService } from "../../../../../app/views/partials/layout/topbar/language-selector/language-selector.service";
import { NgForm } from "@angular/forms";
import { LanguageService } from "../../services/language.service";
import { Subject } from "rxjs";
import * as jwt_decode from "jwt-decode";
import { SharedService } from "../../services/shared.service";
import { DashboardService } from "../../../modules/artiste/dashboard/service/dashboard.service";
import * as Highcharts from "highcharts";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
	selector: "artiste-view-dialog",
	templateUrl: "./artiste-profile-view-dialog.html",
	styleUrls: ["./artiste-profile-view-dialog.scss"],
})
export class ArtisteProfileViewDialog {
	// @Input() data;
    // @Input() userId: string;
	private subscription;
	useProfileViewFilter: string;
	paymentGraph2 = false;
	constructor(public dialogRef: MatDialogRef<ArtisteProfileViewDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private dashboardService: DashboardService) {
	}
	ngOnInit(): void {
		this.useProfileViewFilter = "m";
		// this.getProfileView();
	}

	close(managerForm): void {
		this.dialogRef.close();
	}
	filterProfileView(filterBy) {
		this.useProfileViewFilter = filterBy;
		this.getProfileView();
	}
	getProfileView() {
		this.dashboardService.getArtisteProfileViews(this.useProfileViewFilter, this.data.userId).subscribe((response) => {
			var redData = response.data;
			if (response.status == 1 && redData.xAxis && redData.yAxis) {
				this.paymentGraph2 = true;
				Highcharts.chart("artisteProfileViews", {
					chart: {
						type: "column",
					},
					title: {
						text: "",
					},
					series: redData.yAxis,
					xAxis: { categories: redData.xAxis },
					yAxis: {
						min: 0,
						title: {
							text: "Profile View(s)",
						},
					},
					credits: {
						enabled: false,
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">Total User(s): </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>',
						footerFormat: "</table>",
						shared: true,
						useHTML: true,
					},
				});
				//this.cdr.detectChanges();
			}
		});
	}
}
