import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";

@Component({
	selector: "tdata-social",
	templateUrl: "./social.html",
	styleUrls: ["./social.scss"],
})
export class TDataSocial implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;

	constructor() {
		// this.config = data;
	}

	ngOnInit() {
	}

	ngOnDestroy() {

	}

	formateNumber(num: number){
		return new Intl.NumberFormat('en-IN').format(num);
	}
}
