import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef,EventEmitter,Inject } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";

interface singleCategory {
	_id: string;
	title: string;
	description: string;
	popular: boolean;
	status: number;
	parent: string | null;
	parent_title: string | null;
	order: number;
	subcategory: singleCategory[];
	selected: boolean | false;
	// Add other properties as needed
}

export interface DialogData2 {
	category: Array<singleCategory>;
	profession: Array<string>;
}
@Component({
	selector: "tfilter-profession",
	templateUrl: "./profession.html",
	styleUrls: ["./profession.scss"],
})

	


export class TFilterProfession {
	
	private subscription;
	emailError: any = "";
	otpDisabled = false;
	
	newCategoryArr: Array<singleCategory>;
	private addManager: EventEmitter<boolean> = new EventEmitter();

	constructor(public dialogRef: MatDialogRef<TFilterProfession>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData2) { }
	ngOnInit() {
		this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category)))
			.then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			})
			.catch((err) => { });
	}

	save(): void { }
	formateCategoryWithParent(array: Array<singleCategory>) {
		return new Promise((resolve, reject) => {
			try {
				let newarray: Array<singleCategory> = [];
				for (let index = 0; index < array.length; index++) {
					const element = array[index];
					if (element.title === "Other") continue;
					if (this.data.profession && this.data.profession.includes(element._id)) {
						element.selected = true;
					} else {
						element.selected = false;
					}
					if (!element.parent) {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						if (!tempIndex) newarray.push(element);
					} else if (element.parent != "") {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						let tempObj = newarray[tempIndex];
						if (tempObj) {
							if (tempObj.subcategory) {
								tempObj.subcategory.push(element);
							} else {
								tempObj.subcategory = [element];
							}
							newarray[tempIndex] = tempObj;
						} else {
							let copy = JSON.parse(JSON.stringify(this.data.category));
							const futureElement = copy.find((item: singleCategory) => item._id === element.parent);
							if (futureElement) {
								futureElement.subcategory = [element];
								newarray.push(futureElement);
							}
						}
					}
				}
				newarray.sort((a, b) => {
					if (a.title === "Main") {
						return -1; // 'Main' should come first
					} else if (b.title === "Main") {
						return 1; // 'Main' should come before other elements
					} else if (a.title > b.title) {
						return 1; // Indicates a should come after b
					} else if (a.title < b.title) {
						return -1; // Indicates a should come before b
					} else {
						return 0; // Indicates they are equal in terms of sorting
					}
				});

				// console.log(newarray)
				resolve(newarray);
			} catch (error) {
				reject(error);
			}
		});
	}
	handleCategoryClick(id: string, selected: boolean, childIndex: number, parentIndex: number) {
		this.newCategoryArr[parentIndex].subcategory[childIndex].selected = !selected;
		if (selected) {
			this.data.profession = this.data.profession.filter((e) => e != id);
		} else {
			//add
			if (this.data.profession) {
				this.data.profession.push(id);
			} else {
				this.data.profession = [id];
			}
		}
	}
	onInputChange(event: Event) {
		// You can access the input value using event.target.value
		const inputValue = (event.target as HTMLInputElement).value;

		// Perform your desired actions with the input value
		// console.log('Input value:', inputValue, this.categoryArr);
		if (inputValue == "") {
			this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category))).then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			});
			return;
		}

		let array = JSON.parse(JSON.stringify(this.data.category));
		let newarray22: Array<singleCategory> = [];
		for (let index = 0; index < array.length; index++) {
			const element = array[index];
			if (element.title === "Other") continue;

			if (this.data.profession.includes(element._id)) {
				element.selected = true;
			} else {
				element.selected = false;
			}
			if (!element.parent) {
				const tempIndex = newarray22.findIndex((item) => item._id === element.parent);
				if (!tempIndex) newarray22.push(element);
			} else if (element.parent != "" && element.title.toLowerCase().includes(inputValue.toLowerCase())) {
				const tempIndex = newarray22.findIndex((item) => item._id === element.parent);
				let tempObj = newarray22[tempIndex];
				if (tempObj) {
					if (tempObj.subcategory) {
						tempObj.subcategory.push(element);
					} else {
						tempObj.subcategory = [element];
					}
					newarray22[tempIndex] = tempObj;
				} else {
					let copy = JSON.parse(JSON.stringify(this.data.category));
					const futureElement = copy.find((item: singleCategory) => item._id === element.parent);
					if (futureElement) {
						futureElement.subcategory = [element];
						newarray22.push(futureElement);
					}
				}
			}
		}
		newarray22.sort((a, b) => {
			if (a.title === "Main") {
				return -1; // 'Main' should come first
			} else if (b.title === "Main") {
				return 1; // 'Main' should come before other elements
			} else if (a.title > b.title) {
				return 1; // Indicates a should come after b
			} else if (a.title < b.title) {
				return -1; // Indicates a should come before b
			} else {
				return 0; // Indicates they are equal in terms of sorting
			}
		});
		this.newCategoryArr = newarray22;
		this.newCategoryArr = this.newCategoryArr.filter((e) => e.subcategory && e.subcategory.length > 0);

		this.cdr.detectChanges();
	}
	onKeyDown(event: KeyboardEvent, value: string) {
		if (event.key === "Backspace" && value.length <= 1) {
			this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category))).then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			});
			// let value = document.getElementById("catageorySearchButton").value;
		}
	}
	ngOnDestroy(): void {
		this.newCategoryArr = [];
		this.dialogRef.close(this.data.profession);
	}
}



