import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { throwError } from "rxjs";
export class CommonService {
	menu: any[];

	data: any;
	constructor(private urlModule: any, public http: HttpClient) {}
	baseUrl = environment.baseURL + this.urlModule;

	getAll() {
		return this.http.post(this.baseUrl + "/list", {}).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	getArtistForTeam() {
		return this.http.post(this.baseUrl + "/artistForTeamMembers", {}).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	getAllAreaInfo(latlong) {
		return this.http.post(this.baseUrl + "/getAreaInfo", latlong).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	searchAll(searchObj) {
		return this.http.post(this.baseUrl + "/list", searchObj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	getOne(id) {
		return this.http.get(this.baseUrl + "/" + id).pipe(
			map((response: any) => {
				
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	//////////////////////////////   get reject option list ///////////
	getTalentRejectList(searchObj) {
		return this.http.post(this.baseUrl + "/rejectionOptions",searchObj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}



	create(resource): Observable<any> {
		return this.http.post(this.baseUrl, resource).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}

	update(resource) {
		return this.http.put(this.baseUrl, resource).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return of(err);
			})
		);
	}
	///////////////// for keyword feature in talent options /////////////// 
	updateKeyWords(keyData){
		return this.http.post(environment.baseURL + "keywords", keyData).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return of(err);
			})
		);
	}


	//////////////////manager invitaion resend /////////////////
	managerInviteAgian(resource){
		return this.http.post(this.baseUrl + '/resend', resource).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}

	delete(id) {
		return this.http.delete(this.baseUrl + "/" + id).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	getCount() {
		return this.http.get(this.baseUrl + "/count").pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	deleteSelected(idsForDeletion) {
		return this.http.post(this.baseUrl + "/updateMany", idsForDeletion).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	updateStatusMany(obj) {
		return this.http.post(this.baseUrl + "/updateMany", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	// udpateUserType
	updateUserType(obj) {
		return this.http.post(this.baseUrl + "/updateUserType", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	

	deleteImage(obj) {
		return this.http.post(this.baseUrl + "/removeImage", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	uploadImage(obj) {
		return this.http.post(this.baseUrl + "/upload", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	artisteUpdateRequest(obj) {
		return this.http.post(environment.baseURL + "profilerequest", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return of(err);
			})
		);
	}

	private handleError<T>(operation = "operation", result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}
	getFormEditLimits(data) {
		return this.http.post(this.baseUrl + "/getFormEditLimits", data).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	export() {
		return this.http.post(this.baseUrl + "/export", []).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	import(resource): Observable<any> {
		return this.http.post(this.baseUrl + "/import", resource).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	sentProfileVerifyOTP(obj) {
		return this.http.post(environment.baseURL + "artiste/profileVerifyOtpSent", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	profileVerifyOtpVerify(obj) {
		return this.http.post(environment.baseURL + "artiste/profileVerifyOtpVerify", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	getPackage(obj) {
		return this.http.post(environment.baseURL + "package/getPackage", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}
	getArtistTeam(obj) {
		return this.http.post(environment.baseURL + "team/getMyTeams", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}
	getMyartistAndTeam(obj) {
		return this.http.post(environment.baseURL + "chat/getMyTeamAndArtist", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return err;
			})
		);
	}
}
