import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";

@Component({
	selector: "tdata-status",
	templateUrl: "./status.html",
	styleUrls: ["./status.scss"],
})
export class TDataStatus implements OnInit, OnDestroy {
	@Input() config;
	@Input() values;
	@Input() data;

	constructor() {}

	value = { key: "--", color: "#000000" };

	ngOnInit() {
		if (this.values && this.values.length > 0) {
			let data = this.values.filter((e) => e.value == this.data.status);

			if (data.length > 0) {
				this.value = data[0];
			}
		}
	}

	ngOnDestroy() {}
}
