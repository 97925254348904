import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, EventEmitter, Inject } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";

interface singleCategory {
	_id: string;
	title: string;
	description: string;
	popular: boolean;
	status: number;
	parent: string | null;
	parent_title: string | null;
	order: number;
	subcategory: singleCategory[];
	selected: boolean | false;
	// Add other properties as needed
}

export interface DialogData2 {
	category: Array<singleCategory>;
	profession: Array<string>;
	CategoryTitle: string;
	editProfessionButton: any;
}
@Component({
	selector: "tfilter-profession",
	templateUrl: "./profession.html",
	styleUrls: ["./profession.scss"],
})
export class TFilterProfession {
	private subscription;
	emailError: any = "";
	otpDisabled = false;
	selectedCatArr = [];
	inputValue = "";
	newCategoryArr: Array<singleCategory>;
	private addManager: EventEmitter<boolean> = new EventEmitter();
	SaveButtonToggle = false;
	constructor(public dialogRef: MatDialogRef<TFilterProfession>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData2) {}
	ngOnInit() {
		this.selectedCatArr = [...this.data.profession];
		this.formateCategoryWithParent();	
	}

	save(): void {}

	formateCategoryWithParent(inputValue = "") {
		const getParent = new Set(this.data.category.map((e) => e.parent_title));
		let categoryData = [];
		getParent.forEach((e) => {
			if (e) {
				categoryData.push({
					title: e,
					data: this.data.category.filter((c) => c.parent_title == e && !this.selectedCatArr.map((s) => s._id).includes(c._id) && c.title && c.title.toLowerCase().includes(inputValue)),
				});
			}
		});
		categoryData = categoryData.filter((e) => e.data.length != 0);
		categoryData.sort((a, b) => {
			if (a.title.toLowerCase() === "main") return -1;
			return a.title.localeCompare(b.title);
		});
		categoryData = categoryData.map((e) => ({ ...e, title: e.title.toLowerCase() == "main" ? "" : e.title }));

		this.newCategoryArr = categoryData;
		this.cdr.detectChanges();
	}
	handleCategoryClick(category: singleCategory) {
		this.SaveButtonToggle = true
		this.selectedCatArr.push(category);
		this.formateCategoryWithParent(this.inputValue.toLowerCase());
	}
	onInputChange(event: Event) {
		// You can access the input value using event.target.value
		this.inputValue = (event.target as HTMLInputElement).value;
		this.formateCategoryWithParent(this.inputValue.toLowerCase());
		this.cdr.detectChanges();
	}
	onKeyDown(event: KeyboardEvent, value: string) {
		if (event.key === "Backspace" && value.length <= 1) {
			this.formateCategoryWithParent();
			// let value = document.getElementById("catageorySearchButton").value;
		}
	}

	submitCategory() {
		// this.ngOnDestroy();
		this.newCategoryArr = [];
		this.dialogRef.close(this.selectedCatArr);
	}
	resetCategory() {
		this.selectedCatArr = [];
	}
	closeDialoge() {
		this.dialogRef.close(this.selectedCatArr.length == 0 ? [] : this.data.profession);
	}

	removePill(pill): void {
		this.SaveButtonToggle = true
		this.selectedCatArr = this.selectedCatArr.filter((keyword) => keyword._id !== pill._id);
		this.formateCategoryWithParent();
	}

	// ngOnDestroy(): void {
	// 	this.newCategoryArr = [];
	// 	this.dialogRef.close(this.selectedCatArr);
	// }
}
