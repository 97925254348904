// Angular
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule } from "@angular/material";
// NgBootstrap
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Perfect Scrollbar
// Core module
import { CoreModule } from "../../core/core.module";

// CRUD Partials
import { ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent } from "./content/crud";
// Layout partials
import { ContextMenu2Component, ContextMenuComponent, LanguageSelectorComponent, NotificationComponent, QuickActionComponent, QuickPanelComponent, ScrollTopComponent, SearchDefaultComponent, SearchDropdownComponent, SearchResultComponent, SplashScreenComponent, Subheader1Component, UserProfileComponent } from "./layout";
// General
import { NoticeComponent } from "./content/general/notice/notice.component";
import { PortletModule } from "./content/general/portlet/portlet.module";
// Errpr
import { ErrorComponent } from "./content/general/error/error.component";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
import { CartComponent } from "./layout/topbar/cart/cart.component";
import { CountrySelectorComponent } from "./layout/topbar/country-selector/country-selector.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from "@ngx-translate/core";
import { ListingComponent } from "../../core/common/component/listing/listing.component";
import { BreadcrumbsComponent } from "../../core/common/component/breadcrumbs/breadcrumbs.component";

import { ListingcouponsComponent } from "../../core/common/component/listing-coupons/listing.component";
import { ListingartisteComponent } from "../../core/common/component/listing-artiste/listing.component";
import { ListingmanagerartisteComponent } from "../../core/common/component/listing-manager-artiste/listing.component";
import { ListingmanagersComponent } from "../../core/common/component/listing-manager/listing.component";
import { ListingsubscriptionComponent } from "../../core/common/component/listing-subscription/listing.component";
import { RejectDialoge } from "../../core/common/component/reject-dialoge/rejectDialoge";
import { Reject } from "../../core/common/component/accordion/accordion";
import { ListingartistemanagersComponent } from "../../core/common/component/listing-artiste-manager/listing.component";

import { ArtisteProfileViewDialog } from "../../core/common/component/artiste-profile-view-dialog/artiste-profile-view-dialog";

import { ManagerArtisteProfileViewDialog } from "../../core/common/component/listing-manager-artiste/listing.component";
import { TheaderToggle } from "../../core/common/component/tableheaders/toggle/toggle";
import { TDataProfile } from "../../core/common/component/table-data/profile/profile";
import {TDataProfilePopUp} from "../../core/common/component/table-data/profile-pop-up/profile-pop-up"
import { TDataSocial } from "../../core/common/component/table-data/social/social";
import { TDataShowInSearch } from "../../core/common/component/table-data/checkbox/checkbox";
import { TdataActions } from "../../core/common/component/table-data/actions/actions";
import { TDataDate } from "../../core/common/component/table-data/date/date";
import { TDataConnectionLimit } from "../../core/common/component/table-data/input-filed/input-filed";
import { TDataStatus } from "../../core/common/component/table-data/status/status";
import { TdataStats } from "../../core/common/component/table-data/stats/stats";
import { TFilterDropDown } from "../../core/common/component/table/dropdown/dropdown";
import {TFilterProfession } from "../../core/common/component/table/professions/profession"
import { TDataProfileViewDialog1 } from "../../core/common/component/table-data/stats/stats";
import {TFilterDate} from "../../core/common/component/table/date/date";

@NgModule({
	declarations: [
		ScrollTopComponent,
		NoticeComponent,
		ListingComponent,
		ListingcouponsComponent,
		ListingartisteComponent,
		ListingmanagerartisteComponent,
		ArtisteProfileViewDialog,
		ManagerArtisteProfileViewDialog,
		ListingmanagersComponent,
		ListingartistemanagersComponent,
		ListingsubscriptionComponent,
		RejectDialoge,
		Reject,
		BreadcrumbsComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		Subheader1Component,
		LanguageSelectorComponent,
		CountrySelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CartComponent,
		ErrorComponent,

		// table components
		TheaderToggle,
		TDataProfile,
		TDataProfilePopUp,
		TDataSocial,
		TDataShowInSearch,
		TdataActions,
		TFilterDropDown,
		TFilterProfession,
		TDataDate,
		TDataConnectionLimit,
		TDataProfileViewDialog1,
		TFilterDate,
		TDataStatus,
		TdataStats,
	],
	exports: [
		PortletModule,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		ListingComponent,
		ListingcouponsComponent,
		ListingartisteComponent,
		ListingmanagerartisteComponent,
		ListingmanagersComponent,
		ListingartistemanagersComponent,
		ListingsubscriptionComponent,
		RejectDialoge,
		Reject,
		ArtisteProfileViewDialog,
		ManagerArtisteProfileViewDialog,
		BreadcrumbsComponent,
		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		Subheader1Component,
		LanguageSelectorComponent,
		CountrySelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		CartComponent,
		ErrorComponent,
		// table component
		TheaderToggle,
		TDataProfile,
		TDataProfilePopUp,
		TDataSocial,
		TDataShowInSearch,
		TdataActions,
		TFilterDropDown,
		TFilterProfession,
		TDataDate,
		TDataConnectionLimit,
		TDataProfileViewDialog1,
		TFilterDate,
		TDataStatus,
		TdataStats,
	],
	imports: [TranslateModule, CommonModule, PerfectScrollbarModule, RouterModule, FormsModule, NgbModule, InlineSVGModule, CoreModule, PortletModule, MatButtonModule, MatMenuModule, MatSelectModule, MatInputModule, MatTableModule, MatAutocompleteModule, MatRadioModule, MatIconModule, MatNativeDateModule, MatProgressBarModule, MatDatepickerModule, MatCardModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatProgressSpinnerModule, MatSnackBarModule, MatTabsModule, MatTooltipModule, MatDialogModule, ReactiveFormsModule],
	entryComponents: [
		UpdateStatusDialogComponent,
		DeleteEntityDialogComponent,
		ManagerArtisteProfileViewDialog,
		RejectDialoge,
		Reject,
		//table component
		TheaderToggle,
		TDataProfile,
		TDataProfilePopUp,
		TDataSocial,
		TDataShowInSearch,
		TdataActions,
		TFilterDropDown,
		TFilterProfession,
		TDataDate,
		TDataConnectionLimit,
		TDataProfileViewDialog1,
		TFilterDate,
		TDataStatus,
		TdataStats,
		ArtisteProfileViewDialog
	],
})
export class PartialsModule {}
