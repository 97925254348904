const appStatus = [
	{
		module: ["artiste", "manage", "customer"],
		artisteFilter: ["STATUS", "PROFESSION", "DATERANGE"],
		values: [
			{ value: [0, 1, 3, 4, 5], key: "All", color: "#1a1c3a" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 3, key: "Requested", color: "#8184ff" },
			{ value: 4, key: "Incomplete", color: "#ffcd17" },
			{ value: 5, key: "Reject", color: "#e05252" },
			{ value: 0, key: "Inactive", color: "#b8bbc6" },
		],
	},
	{
		module: ["team"],
		values: [
			{ value: [1, 2, 3, 0], key: "All", color: "#1a1c3a" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 2, key: "Requested", color: "#8184ff" },
			{ value: 3, key: "Reject", color: "#e05252" },
			{ value: 0, key: "Inactive", color: "#b8bbc6" },
		],
	},
	{
		module: ["package", "profilerequest", "occupationsuggestion", "usergroup", "search", "sociallink", "user", "label", "category", "occupation", "module", "menu"],
		profilerequestFilter:["DATERANGE"],
		occupationsuggestionFilter:["DATERANGE"],
		searchFilter:["DATERANGE"],
		labelFilter:["STATUS"],
		moduleFilter:["STATUS"],
		menuFilter:["STATUS"],
		values: [
			{ value: [0, 1], key: "All", color: "#1a1c3a" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 0, key: "Inactive", color: "#b8bbc6" },
		],
	},
	{
		module: ["chat"],
		chatFilter: ["DATERANGE"],
		values: [
			{ value: [1, 2, 3, 4], key: "All", color: "#1a1c3a" },
			// { value: 1, key: "Active", color: "#2e7de0" },
			// { value: 2, key: "Active", color: "#2e7de0" },
			// { value: 3, key: "Sent", color: "#077711" },
			// { value: 4, key: "Error", color: "FF746C" },
		],
	},
	{
		module: ["notification"],
		values: [
			{ value: [0, 1, 3, 4], key: "All", color: "#1a1c3a" },
			{ value: 0, key: "Inactive", color: "#b8bbc6" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 3, key: "Sent", color: "#8184ff" },
			{ value: 4, key: "Error", color: "#e05252" },
		],
	},
	{
		module: ["feedback"],
		values: [
			{ value: [0, 1, 3], key: "All", color: "#1a1c3a" },
			{ value: 0, key: "Inactive", color: "#b8bbc6" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 3, key: "Resolved", color: "#8184ff" },
		],
	},
	{
		module: ["deactivate"],
		values: [
			{ value: [1, 2, 3], key: "All", color: "#1a1c3a" },
			{ value: 1, key: "Active", color: "#2e7de0" },
			{ value: 2, key: "Deleted", color: "#e05252" },
			{ value: 3, key: "Canceled By User", color: "#ffcd17" },
		],
	},
	{
		module: ["consolenotification"],
		values: [
			{ value: [1, 0], key: "All", color: "#1a1c3a" },
			{ value: 1, key: "Unread", color: "#08976d" },
			{ value: 0, key: "Viewed", color: "#ffb822" },
		],
	},
	{
		module: ["managerinvite"],
		managerinviteFilter:["DATERANGE"],
	}
];

export default appStatus;
