import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { ConnectionModel } from "../../../../modules/artiste/model/connection-model";
@Component({
	selector: "tdata-input",
	templateUrl: "./input-filed.html",
	styleUrls: ["./input-filed.scss"],
})
export class TDataConnectionLimit implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;
	inputValue: any;
	// editMode = false;
	editMode: boolean = false;
	constructor() {}

	ngOnInit() {
		this.inputValue = this.data.inputValue;
	}
	toggleEditMode() {
		if (this.editMode) {
			// Perform any validation or saving logic here before updating the value
			// E.g., if you want to ensure that the dailyConnectionLimit has been updated correctly
			// this.config.data.dailyConnectionLimit = this.config.data.dailyConnectionLimit.toString().padStart(2, "0");
			this.config.dataConf.inputFiledClick(this.data.id, this.inputValue);
		}
		this.editMode = !this.editMode;
	}
	ngOnDestroy() {}
}
