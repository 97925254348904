import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";

@Component({
	selector: "tfilter-date",
	templateUrl: "./date.html",
	styleUrls: ["./date.scss"],
})
export class TFilterDate implements OnInit, OnDestroy {
	@Input() click: any;
	@Input() gteDate: any;
	@Input() lteDate: any;
	@Output() close = new EventEmitter<void>();
	selectedStartDate: any;
	selectedEndDate: any;
	obj: any = {};
	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.gteDate) {
			this.selectedStartDate = new Date(this.gteDate);
		}
		if (this.lteDate) {
			this.selectedEndDate = new Date(this.lteDate);
		}
	}

	onStartDateChange() {
		if (this.selectedStartDate) {
			const startDate = new Date(this.selectedStartDate.getTime() - this.selectedStartDate.getTimezoneOffset() * 60000).toISOString();
			this.obj["startDate"] = startDate;
		}
		if (this.selectedEndDate) {
			const endDate = new Date(this.selectedEndDate.getTime() - this.selectedEndDate.getTimezoneOffset() * 60000).toISOString();
			this.obj["endDate"] = endDate;
		}
		this.click(this.obj);
	}

	endDateFilter = (d: Date | null): boolean => {
		if (!this.selectedStartDate) {
			return true; // Allow all dates if no start date is selected
		}
		return d ? d >= this.selectedStartDate : false; // Disable dates before the start date
	};

	ngOnDestroy() {
		this.close.emit(this.obj);
	}
}

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: "DD-MM-YYYY", // For parsing date input in the DD-MM-YYYY format
	},
	display: {
		dateInput: "DD-MM-YYYY", // How the date will be displayed in the input field
		monthYearLabel: "MMM YYYY", // The format for the month and year label
		dateA11yLabel: "LL", // Accessibility label for full date
		monthYearA11yLabel: "MMMM YYYY", // Accessibility label for month and year view
	},
};
