import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit, OnDestroy, Input, Inject, ChangeDetectorRef } from "@angular/core";
// import { TDataProfileViewDialog1 } from "../stats-profile-view/stats-profile-view";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
// ../../../modules/artiste/dashboard/service/dashboard.service
import { DashboardService } from "../../../../modules/artiste/dashboard/service/dashboard.service";
import * as Highcharts from "highcharts";

@Component({
	selector: "tdata-stats",
	templateUrl: "./stats.html",
	styleUrls: ["./stats.scss"],
})
export class TdataStats implements OnInit, OnDestroy {

	@Input() config;
	@Input() data;
	@Input() displayCheckBox2;

	constructor(private dialog: MatDialog) {
	}

	ngOnInit() {}

	openProfileViewDialog(userId): void {
		const dialogRef = this.dialog.open(TDataProfileViewDialog1, {
			width: "800px",
			panelClass: "icon-outside",
			data: { userId: userId },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
			} else {
			}
		});
	}

	ngOnDestroy() {}
}

export interface DialogData {
	userId: string;
}

@Component({
	selector: "stats-profile-view-dialog",
	templateUrl: "stats-profile-view.html",
	styleUrls: ["stats-profile-view.css"],
})
export class TDataProfileViewDialog1 {
	private subscription;
	useProfileViewFilter: string = "m";
	paymentGraph2 = false;
	constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<TDataProfileViewDialog1>, private dashboardService: DashboardService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

	ngOnInit() {
		this.useProfileViewFilter = "m";
	}

	close(managerForm): void {
		this.dialogRef.close();
		this.cdr.detectChanges();
	}
	filterProfileView(filterBy) {
		this.useProfileViewFilter = filterBy;
		this.getProfileView();
	}
	getProfileView() {
		this.dashboardService.getArtisteProfileViews(this.useProfileViewFilter, this.data.userId).subscribe((response) => {
			var redData = response.data;
			if (response.status == 1 && redData.xAxis && redData.yAxis) {
				this.paymentGraph2 = true;
				Highcharts.chart("artisteProfileViews", {
					chart: {
						type: "column",
					},
					title: {
						text: "",
					},
					series: redData.yAxis,
					xAxis: { categories: redData.xAxis },
					yAxis: {
						min: 0,
						title: {
							text: "Profile View(s)",
						},
					},
					credits: {
						enabled: false,
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">Total User(s): </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>',
						footerFormat: "</table>",
						shared: true,
						useHTML: true,
					},
				});
				//this.cdr.detectChanges();
			}
		});
	}
	ngOnDestroy(): void {
		// this.newCategoryArr = [];
		this.dialogRef.close();
	}
}
