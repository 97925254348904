import { ViewChild, Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Injector, ComponentFactoryResolver, HostListener } from "@angular/core";
import { CsTableResource } from "./utils/custom-table-source";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TFilterProfession } from "../table/professions/profession";
import appStatus from "../table-data/status/appStatus.js";

@Component({
	selector: "kt-listing",
	templateUrl: "./listing.component.html",
	styleUrls: ["./listing.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingComponent implements OnInit, OnDestroy {
	@ViewChild(ListingComponent, { static: false }) listingComponent!: ListingComponent;
	@Input() columnsConfig;
	@Input() getData;
	@Input() moduleName: String;
	@Input() handleAdd: any;
	@Input() export: any;
	@Input() headingTitle: any;
	@Input() getCategoryData: any;

	@ViewChild("mySelect", null) mySelect;
	showHoverRowId = "";
	selectedRows = [];
	getSelectedProfession = [];

	dropdownVisible: boolean = false;
	openFilter: boolean = false;
	showAdvanceSearch: boolean = false;

	// data
	tableData = [];

	// model filters
	modelFilter = ["STATUS","DATERANGE"];

	// handle column resize fn
	private startX: number;
	private startWidth: number;
	private isResizing = false;
	private currentResizingElement: HTMLElement | null = null;
	tableObj = new CsTableResource();
	private debounceTimer;

	// default status dropdown
	// TODO: changes to component level
	defaultStatus = [];

	constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private injector: Injector, private resolver: ComponentFactoryResolver) {}

	async ngOnInit() {
		// console.log("========this.moduleName=======", this.moduleName);
		if (appStatus && appStatus.length) {
			let data = appStatus.filter((e) => e.module.includes(this.moduleName));

			if (data.length > 0) {
				this.defaultStatus = data[0]["values"];
				if(data[0][this.moduleName + "Filter"]){

					this.modelFilter = data[0][this.moduleName + "Filter"];
				}
			}
		}

		let initialFilter = this.tableObj.getInitialValue(this.moduleName);
		let data;
		if (initialFilter) {
			data = await this.getData(initialFilter);
		} else {
			data = await this.getData();
		}
		this.tableObj.setDefaultValues(data.filter, data.totalCount);
		this.tableObj.getInitialValue(this.moduleName);
		this.tableData = data.data;
		this.cdr.detectChanges();
	}

	clearSearch() {
		this.tableObj.filter.search = "";
		this.getTableData(this.tableObj.filter);
	}

	/**
	 * used this fn to get data of row and pass as parameter to assign component.
	 * @param dataFields
	 * @param data
	 * @returns
	 */
	getTemplateData(dataFields, data) {
		let result = {};
		
		if (dataFields) {
			Object.keys(dataFields).forEach((element) => {
				result[element] = data[dataFields[element]];
			});
		}
		result["id"] = data["_id"];
		
		return result;
	}

	handleProfessionFilter() {
		const dialogRef = this.dialog.open(TFilterProfession, {
			data: { category: this.getCategoryData, profession: JSON.parse(JSON.stringify(this.getSelectedProfession)) },
			width: "80vw",
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.getSelectedProfession = result;
				this.cdr.detectChanges();
			}
		});
	}

	getProfessionTitle(profession: any) {
		let titleArr = [];
		for (let result of profession) {
			let title = this.getCategoryData.filter((m) => result === m._id).map((m) => m.title);
			titleArr.push(" " + title);
		}
		return titleArr;
	}

	/**
	 * hanlde how effect
	 * @param event
	 * @param id
	 */
	mouseenter(event, id) {
		this.showHoverRowId = id;
		this.cdr.detectChanges();
	}

	mouseleave(event) {
		this.showHoverRowId = "";
		this.cdr.detectChanges();
	}

	handleSelectRow(e, id) {
		if (this.selectedRows.includes(id)) {
			this.selectedRows = this.selectedRows.filter((row) => row !== id);
		} else {
			this.selectedRows.push(id);
		}
		this.cdr.detectChanges();
	}

	handleSelectAllRows(e) {
		if (e.checked) {
			this.tableData.forEach((row) => this.selectedRows.push(row._id));
		} else {
			this.selectedRows = [];
		}
		this.cdr.detectChanges();
	}

	isAllRowsSelected() {
		return this.tableData.length != 0 && this.tableData.every((row) => this.selectedRows.includes(row._id));
	}

	/**
	 * used fn to call getData api and set table configuration
	 * @param filter
	 */
	async getTableData(filter) {
		filter = { ...filter, limit: this.tableObj.displayRecords, sortField: this.tableObj.sortField, sortOrder: this.tableObj.sortOrder, skip: this.tableObj.skipRecords };
		let data = await this.getData(filter);
		this.tableData = data.data;
		this.tableObj.updatePagination(data.totalCount);
		this.tableObj.updateFilter(data.filter);
		this.cdr.detectChanges();
	}

	async reloadData() {
		this.getTableData(this.tableObj.filter);
	}

	// filter functions
	onInputChange(e) {
		this.tableObj.updateFilter({ ...this.tableObj.filter, search: e.target.value });
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(() => {
			this.getTableData(this.tableObj.filter);
		}, 300);
	}

	toggleFilter() {
		this.openFilter = !this.openFilter;
	}

	openAdvanceSearch() {
		this.showAdvanceSearch = !this.showAdvanceSearch;
	}

	/**
	 * handle dropdown selection
	 * @param option
	 */
	// TODO: create dynamic selection with key and value
	handleDropdownSearch(option) {
		let filter = { ...this.tableObj.filter, filter: { ...this.tableObj.filter.filter, status: option.value } };
		if (Array.isArray(option.value)) {
			filter.filter.status = { $in: option.value };
		}
		this.tableObj.updateFilter(filter);
	}

	handleDateFilter(option) {
		this.tableObj.gteDate = option.startDate;
		this.tableObj.lteDate = option.endDate;
	}

	resetFilter() {
		this.toggleFilter();
		this.tableObj.resetValue();
		// TODO: change filter status based on component, at parent level handle this change.
		this.tableObj.gteDate = "";
		this.tableObj.lteDate = "";
		this.getTableData({ ...this.tableObj.filter, filter: {}, search: null });
	}

	/**
	 *	use fn to create filter based on input in advance search
	 */
	applyFilter() {
		this.toggleFilter();
		if (this.tableObj.gteDate || this.tableObj.lteDate) {
			let createdAt = {};
			if (this.tableObj.gteDate) {
				createdAt["$gte"] = this.tableObj.gteDate;
			}
			if (this.tableObj.lteDate) {
				createdAt["$lte"] = this.tableObj.lteDate;
			}

			this.tableObj.filter.filter["createdAt"] = createdAt;
		}
		if (this.getSelectedProfession.length > 0) {
			this.tableObj.filter.filter["professions"] = { $in: this.getSelectedProfession };
		}
		this.getTableData(this.tableObj.filter);
	}

	/**
	 * handle sort : ascending or descending
	 * @param field
	 */
	handleSort(field) {
		if (field == this.tableObj.sortField && this.tableObj.sortOrder == "desc") {
			this.tableObj.sortOrder = "asc";
		} else {
			this.tableObj.sortField = field;
			this.tableObj.sortOrder = "desc";
		}
		this.getTableData(this.tableObj.filter);
	}

	onMouseDown(event: MouseEvent): void {
		this.initializeResize(event);
	}

	onMouseMove = (event: MouseEvent): void => {
		if (this.isResizing) {
			this.resizeColumn(event);
		}
	};

	onMouseUp = (): void => {
		this.endResize();
	};

	// /** Initialize resizing parameters and set up event listeners */
	private initializeResize(event: MouseEvent): void {
		this.startX = event.pageX;
		this.startWidth = 200;
		this.isResizing = true;

		const targetElement = event.target as HTMLElement;
		const parentElement = targetElement.parentElement;
		if (parentElement.style.width && parentElement.style.width.replace("px", "")) {
			this.startWidth = parseFloat(parentElement.style.width.replace("px", ""));
		}
		// Attach listeners to the specific target element to avoid affecting the whole document
		document.addEventListener("mousemove", this.onMouseMove);
		document.addEventListener("mouseup", this.onMouseUp);
		this.currentResizingElement = parentElement;
	}

	/** Adjust column width dynamically based on mouse movement */
	private resizeColumn(event: MouseEvent): void {
		const newWidth = this.startWidth + (event.pageX - this.startX);
		this.currentResizingElement.style.width = newWidth + "px";
		const elements = document.querySelectorAll(`[cellid='${this.currentResizingElement.id}']`);
		elements.forEach((e) => {
			(e as HTMLElement).style.width = `${newWidth}px`;
		});
	}

	/** Cleanup event listeners after resizing is done */
	private endResize(): void {
		this.isResizing = false;
		// Remove listeners from the specific element
		if (this.currentResizingElement) {
			// this.currentResizingElement.removeEventListener("mousemove", this.onMouseMove);
			document.body.removeEventListener("mouseup", () => {});
			document.body.removeEventListener("mousemove", () => {});
		}
	}

	// handle pagination
	toggleDropdown() {
		this.dropdownVisible = !this.dropdownVisible;
	}

	// close data limit selection when click out side dropdown ///////
	@HostListener("document:click", ["$event"])
	onDocumentClick(event: MouseEvent) {
		const clickedElement = event.target as HTMLElement;
		const isInsideDropdown = clickedElement.closest(".dropdown-container");

		// Close the dropdown if clicked outside
		if (!isInsideDropdown) {
			this.dropdownVisible = false;
		}
	}

	// Function to select an option and hide the dropdown
	selectOption(option: number) {
		this.tableObj.displayRecords = option;
		this.dropdownVisible = false;
		this.getTableData(this.tableObj.filter);
	}

	handleBackPageination() {
		if (this.tableObj.skipRecords > 0) {
			if (this.tableObj.skipRecords - this.tableObj.displayRecords >= 0) {
				this.tableObj.skipRecords = this.tableObj.skipRecords - this.tableObj.displayRecords;
				this.getTableData(this.tableObj.filter);
			} else {
				this.tableObj.skipRecords = 0;
				this.getTableData(this.tableObj.filter);
			}
		}
	}

	handleSkipPageination() {
		if (this.tableObj.skipRecords + this.tableObj.displayRecords < this.tableObj.totalRecords) {
			this.tableObj.skipRecords = this.tableObj.skipRecords + this.tableObj.displayRecords;
			this.getTableData(this.tableObj.filter);
		}
	}

	ngOnDestroy() {
		// this.subscription.unsubscribe();
	}
}
